import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const ProductPicker = (props) => {
	const data = useStaticQuery(graphql`{
  allProductsDataJson {
    edges {
      node {
        id
        title
        linkTo
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 136, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
}
`);

	return (
        <div className="pickerContainer">
			<div className="pickers">
				{data.allProductsDataJson.edges.map((item) => {
					return (
            <div key={item.node.id} onClick={props.productSelectorHandler}>
							<Link value={item.node.id} to={item.node.linkTo}>
								<div className="pickerButton">
									<div className="pickedImageDiv">
										<GatsbyImage
                      image={item.node.image.childImageSharp.gatsbyImageData}
                      className="pickerProductImage"
                      alt="sectionPic"
                      loading="eager" />
										<div className="hoverContent">
											<FormattedMessage id={item.node.title} />
										</div>
									</div>
								</div>
							</Link>
						</div>
          );
				})}
			</div>
		</div>
    );
};

export default ProductPicker;
