import React, { useState, useEffect, useRef } from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import ProductsHome from "../components/products/ProductsHome";
import ProductPicker from "../components/products/ProductPicker";
import SelectedProduct from "../components/products/SelectedProduct";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import FadeIn from "../components/animations/FadeIn";
import { gsap } from "gsap";

const Products = ({ intl, location }) => {

	const [selectedProduct, setselectedProduct] = useState();

	const productSelectorHandler = event => {
		const productId = event.target.value;
		setselectedProduct(productId);

	};

	useEffect(() => {
		if (location.hash.slice(1) !== selectedProduct) {
			setselectedProduct(location.hash.slice(1));
		}
	}, [location.hash, selectedProduct]);

    useEffect(() => {
		const globalWindow = window || {};
		globalWindow.scrollTo(0,0)
	}, [selectedProduct])

	let boxProducts = useRef(null);
	
	useEffect(() => {
        gsap.fromTo(boxProducts.current, {opacity: 0}, {duration: .5, opacity: 1, delay: 1.5})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<SEO title={intl.formatMessage({ id: "navbar.products" })} />
			<div className="productsPackPage" >
				<div className="gridProductsPackSectionHome">
					<div className="boxProductsPackSideText">
						<FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
					</div>

					<div className="boxProductsPackLeft ">
						<div className="productsSelectorContainer">
							{!selectedProduct ? (
								<ProductsHome />
							) : (
								<SelectedProduct
								selectedProductProp={selectedProduct}
								/>
							)}
						</div>
					</div>
					<div className="boxProductsPackRight" ref={boxProducts}>
						<ProductPicker selectedProductProp={productSelectorHandler} />
					</div>

					<div className="boxProductsPackEmptyRight"></div>

					<div className="boxProductsPackBottomText">
						<BottomFactory />
					</div>
				</div>
			</div>
		</>
	);
};

export default injectIntl(Products);
