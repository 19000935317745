import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionPanel from "@material-ui/core/Accordion";
import MuiAccordionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
	root: {
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: "auto",
		},
	},
	expanded: {},
})(MuiAccordionPanel);

const AccordionSummary = withStyles({
	root: {
		marginBottom: -1,
		minHeight: 56,
		"&$expanded": {
			minHeight: 56,
		},
	},
	content: {
		"&$expanded": {
			margin: "12px 0",
		},
	},
	expanded: {},
})(MuiAccordionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionPanelDetails);

const SelectedProduct = (props) => {
	const data = useStaticQuery(graphql`{
  allProductsDataJson {
    edges {
      node {
        imageSelected {
          childImageSharp {
            gatsbyImageData(width: 250, quality: 100, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        id
        title
      }
    }
  }
  allHorizontalMachinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        subTitle
        goTo
        canDoProducts
      }
    }
  }
  allVerticalMachinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoProducts
      }
    }
  }
  allWeighingDevicesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoProducts
      }
    }
  }
  allTransportersDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 75, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoProducts
      }
    }
  }
  allProcessLinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(width: 150, quality: 100, layout: FIXED, placeholder: NONE)
          }
        }
        title
        goTo
        canDoProducts
      }
    }
  }
}
`);
	const [expanded, setExpanded] = useState();

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const [loadedProduct, setloadedProduct] = useState({});

	const loadProduct = () => {
		const loadedProductConst = {
			id: props.selectedProductProp,
		};
		setloadedProduct(loadedProductConst);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {loadProduct()}, [props.selectedProductProp]);

	return (
        <div className="selectedProductPack">
			<div className="selectedProductPackImageBox">
				{data.allProductsDataJson.edges.map((item, index) => {
					const thisProduct = loadedProduct.id;
					const isTrue = item.node.id;
					return isTrue === thisProduct ? (
						<GatsbyImage
                            image={item.node.imageSelected.childImageSharp.gatsbyImageData}
                            key={index}
                            className="selectedProductPackImage"
                            alt="sectionPic"
                            loading="eager"
							/>
					) : null;
				})}
			</div>

			<div className="selectedMainBox">
				<div className="selectedProductTextBox">
					{data.allProductsDataJson.edges.map((item, index) => {
						const thisProduct = loadedProduct.id;
						const isTrue = item.node.id;
						return isTrue === thisProduct ? (
							<div className="selectedProductTextDiv" key={index}>
								<p className="selectedProductPackTitle">
									<FormattedMessage id={item.node.title} />
								</p>
								<p className="selectedCanDoTitle">
									<FormattedMessage id="products.machines" />
								</p>
							</div>
						) : null;
					})}
				</div>
				<div className="productPackAccordion">
					{data.allHorizontalMachinesDataJson.edges.some((item) => {
						const thisProduct = props.selectedProductProp;
						const isTrue = item.node.canDoProducts.includes(thisProduct);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel1"}
							onChange={handleChange("panel1")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1d-content"
								id="panel1d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductHorizontalMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.horizontal" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allHorizontalMachinesDataJson.edges.map(
										(item, index) => {
											const thisProduct = props.selectedProductProp;
											const isTrue = item.node.canDoProducts.includes(
												thisProduct
											);
											if (isTrue)
												return (
                                                    <li key={index} className="selectedJsonLists">
														<Link to={item.node.goTo}>
															<GatsbyImage
                                                                image={item.node.image.childImageSharp.gatsbyImageData}
                                                                className="selectedJsonImage"
                                                                alt="horizontalImages"
                                                                loading="eager" />
															<p className="selectedJsonTitle">
																<FormattedMessage id={item.node.subTitle} />
															</p>
														</Link>
													</li>
                                                );
											return null;
										}
									)}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>

				<div className="productPackAccordion">
					{data.allVerticalMachinesDataJson.edges.some((item) => {
						const thisProduct = props.selectedProductProp;
						const isTrue = item.node.canDoProducts.includes(thisProduct);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel2"}
							onChange={handleChange("panel2")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2d-content"
								id="panel2d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductVerticalalMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.vertical" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allVerticalMachinesDataJson.edges.map((item, index) => {
										const thisProduct = props.selectedProductProp;
										const isTrue = item.node.canDoProducts.includes(
											thisProduct
										);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															{item.node.title}
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>

				<div className="productPackAccordion">
					{data.allWeighingDevicesDataJson.edges.some((item) => {
						const thisProduct = props.selectedProductProp;
						const isTrue = item.node.canDoProducts.includes(thisProduct);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel3"}
							onChange={handleChange("panel3")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel3d-content"
								id="panel3d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductWeighingDevicesMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.weighing" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allWeighingDevicesDataJson.edges.map((item, index) => {
										const thisProduct = props.selectedProductProp;
										const isTrue = item.node.canDoProducts.includes(
											thisProduct
										);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															<FormattedMessage id={item.node.title} />
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>

				<div className="productPackAccordion">
					{data.allTransportersDataJson.edges.some((item) => {
						const thisProduct = props.selectedProductProp;
						const isTrue = item.node.canDoProducts.includes(thisProduct);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel4"}
							onChange={handleChange("panel4")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel4d-content"
								id="panel4d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductTransportersMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.transporters" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allTransportersDataJson.edges.map((item, index) => {
										const thisProduct = props.selectedProductProp;
										const isTrue = item.node.canDoProducts.includes(
											thisProduct
										);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															<FormattedMessage id={item.node.title} />
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>
				<div className="productPackAccordion">
					{data.allProcessLinesDataJson.edges.some((item) => {
						const thisProduct = props.selectedProductProp;
						const isTrue = item.node.canDoProducts.includes(thisProduct);
						if (isTrue) {
							return true;
						}
						return null;
					}) ? (
						<Accordion
							square
							expanded={expanded === "panel5"}
							onChange={handleChange("panel5")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel5d-content"
								id="panel5d-header"
							>
								<div className="selectedMachineCircleBox">
									<div className="selectedProductProcessLinesMachineTitlesCircle"></div>
									<p>
										<FormattedMessage id="machineSelection.processLines" />
									</p>
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className="selectedJsonBox">
									{data.allProcessLinesDataJson.edges.map((item, index) => {
										const thisProduct = props.selectedProductProp;
										const isTrue = item.node.canDoProducts.includes(
											thisProduct
										);
										if (isTrue)
											return (
                                                <li key={index} className="selectedJsonLists">
													<Link to={item.node.goTo}>
														<GatsbyImage
                                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                                            className="selectedJsonImage"
                                                            alt="horizontalImages"
                                                            loading="eager" />
														<p className="selectedJsonTitle">
															<FormattedMessage id={item.node.title} />
														</p>
													</Link>
												</li>
                                            );
										return null;
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					) : (
						" "
					)}
				</div>
			</div>
		</div>
    );
};

export default injectIntl(SelectedProduct);
